import classNames from 'classnames';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import './content-header.scss';

/**
 * Component for rendering a banner.
 */
const ContentHeader = (props) => {
    let classes = props.classes || [];
    if (props.introduction) {
        classes.push('has-introduction');
    }
    if (props.banner) {
        classes.push('has-banner');
    }
    classes = classes.concat(['content-header']);

    let layoutClasses = props.fullWidth ? [] : ['col-md-10', 'col-lg-8'];
    let rowClasses = props.fullWidth ? [] : ['row'];

    return (
        <div className={classNames(classes)}>
            {props.banner && (
                <div className="content-header--banner">
                    <Img fluid={props.banner.childImageSharp.fluid} />
                </div>
            )}
            <div className="content-header--title-wrapper container">
                <div className={classNames(rowClasses)}>
                    <div className={classNames(layoutClasses)}>
                        <h1>{props.title}</h1>
                        {props.introduction && (
                            <div
                                className="content-header--introduction"
                                dangerouslySetInnerHTML={{
                                    __html: props.introduction,
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

ContentHeader.propTypes = {
    banner: PropTypes.object,
    title: PropTypes.string,
    introduction: PropTypes.string,
    classes: PropTypes.array,
    fullWidth: PropTypes.bool,
};

export default ContentHeader;
