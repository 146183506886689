import PropTypes from 'prop-types';
import React from 'react';

import FieldLink from '../../field/FieldLink/FieldLink';
import './news-teaser.scss';

/**
 * Component for rendering a banner.
 */
const NewsTeaser = (props) => {
    return (
        <article className="news news--teaser card">
            <div className="card-body">
                <FieldLink link={{ uri: props.path.alias }}>
                    <div className="news--date">
                        <time dateTime={props.date}>
                            {props.date_formatted}
                        </time>
                    </div>

                    <h2 className="news--title">{props.title}</h2>

                    {props.summary && props.summary.processed && (
                        <div
                            className="news--summary"
                            dangerouslySetInnerHTML={{
                                __html: props.summary.processed,
                            }}
                        />
                    )}

                    <div className="d-flex justify-content-end news--read-more">
                        <div className="read-more--icon">
                            <i className="bi bi-arrow-right-short"></i>
                        </div>
                    </div>
                </FieldLink>
            </div>
        </article>
    );
};

NewsTeaser.propTypes = {
    date: PropTypes.string,
    date_formatted: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.object,
    path: PropTypes.object,
};

export default NewsTeaser;
