import { Link } from 'gatsby';
import React from 'react';

import './pager.scss';

const Pager = ({ count, current, previous, next }) => {
    return (
        <nav style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{previous && <Link to={previous}>← Previous</Link>}</div>

            <div style={{ justifySelf: 'flex-end' }}>
                {next && <Link to={next}>Next →</Link>}
            </div>
        </nav>
    );
};

export default Pager;
