import classNames from 'classnames';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';
import PropTypes from 'prop-types';
import React from 'react';

import './content-breadcrumb.scss';

/**
 * Component for rendering a banner.
 */
const ContentBreadcrumb = (props) => {
    let classes = props.classes || [];
    if (props.banner) {
        classes.push('has-banner');
    }
    classes = classes.concat(['breadcrumb--wrapper']);
    return (
        <div className={classNames(classes)}>
            <div className="container">
                <Breadcrumb
                    crumbs={props.crumbs}
                    crumbLabel={props.label}
                    crumbSeparator=" > "
                />
            </div>
        </div>
    );
};

ContentBreadcrumb.propTypes = {
    crumbs: PropTypes.array,
    label: PropTypes.string,
    banner: PropTypes.bool,
};

export default ContentBreadcrumb;
