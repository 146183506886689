import { graphql } from 'gatsby';
import React from 'react';

import ContentBreadcrumb from '../content/ContentBreadcrumb/ContentBreadcrumb';
import ContentHeader from '../content/ContentHeader/ContentHeader';
import NewsTeaser from '../content/NewsTeaser/NewsTeaser';
import Pager from '../content/Pager/Pager';
import Layout from '../layout/layout';
import Seo from '../layout/seo';
import './news-index.scss';

const NewsIndexPage = ({ data, pageContext }) => {
    const news = data.news.edges;
    let {
        breadcrumb: { crumbs },
    } = pageContext;
    // Make sure the paginated page is not added to the breadcrumb.
    crumbs = crumbs.slice(0, 2);
    return (
        <Layout>
            <Seo title="News" />
            <ContentBreadcrumb crumbs={crumbs} label="News" />
            <ContentHeader title="News" />
            <div className="container news-overview">
                <div className="row">
                    <div className="col-md-8">
                        {news.map(({ node }, i) => {
                            node.key = `news-${i}`;
                            return <NewsTeaser key={node.key} {...node} />;
                        })}
                        <Pager {...pageContext.pager} />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default NewsIndexPage;

export const query = graphql`
    query ($skip: Int!, $limit: Int!) {
        news: allNodeNews(
            sort: {
                fields: [sticky, field_news_date, created]
                order: [DESC, DESC, DESC]
            }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...nodeNewsTeaserFragment
                }
            }
        }
    }
`;
