import classNames from 'classnames';
import { Link } from 'gatsby';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Component for rendering links.
 *
 * If a link is internal it should pass through gatsby's own router by using
 * the Link component.
 */
const FieldLink = (props) => {
    if (!props.link || !props.link.uri) {
        return props.children ? props.children : null;
    }
    const link = props.link;
    const linkChildren = [link.title, props.children];
    const uri = link.uri_alias ?? link.uri;
    let classes = props.linkClasses || [];
    classes = classes.concat(_get(link, 'options.attributes.class', []));
    const target = _get(link, 'options.attributes.target', false);

    if (target && target === '_blank') {
        classes.push('external');
        return (
            <a
                href={uri}
                target="_blank"
                rel="noreferrer"
                className={classNames(classes)}>
                {linkChildren}
            </a>
        );
    } else if (!uri.includes(':')) {
        return (
            <Link className={classNames(classes)} to={uri}>
                <span>{linkChildren}</span>
            </Link>
        );
    } else if (uri.includes('internal:')) {
        return (
            <Link
                className={classNames(classes)}
                to={uri.replace('internal:', '')}>
                <span>{linkChildren}</span>
            </Link>
        );
    } else {
        classes.push('external');
        return (
            <a href={uri} className={classNames(classes)}>
                <span>{linkChildren}</span>
            </a>
        );
    }
};

FieldLink.propTypes = {
    link: PropTypes.object,
    linkClasses: PropTypes.array,
};

export default FieldLink;
